import { Component, OnInit, inject, OnDestroy } from '@angular/core';
import { Notification } from '../../../../core/interfaces/notification';
import { NotificationService } from '../../../../core/services/notification.service';
import { Subject, takeUntil } from 'rxjs';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent extends BaseComponent implements OnInit, OnDestroy {
  notifications: Array<Notification>|null = null;
  private readonly _notifications = inject(NotificationService);
  protected isArchive: boolean = true;
  protected total_notifications: number = 0;
  pagination_config = {
    skip: 0,
    take: 2,
    orderDirection: 'DESC'
  }
  protected orderD: boolean = false;
  private readonly destroy$: Subject<void> = new Subject();
  showConfirmModal: boolean = false;
  notificationSelected: string = "";

  override ngOnInit(): void {
    this.isArchive = this._router.url == '/app/notifications-archive';
    this.pagination_config = (this.isArchive) ? ({skip: 0, take: 8, orderDirection: 'DESC'}) : ({skip: 0, take: 2, orderDirection: 'DESC'});
    this.getData();
  }

  getData(change: boolean = false): void {
    if (change) {
      this.orderD = !this.orderD;
    }
    this.pagination_config.orderDirection = (this.orderD) ? 'ASC' : 'DESC';
    this._notifications.getNotifications(this.pagination_config).subscribe({
      next: response => {
        this.notifications = response.data;
        this.total_notifications = response.total;
      }
    });
  }

  /**
   * Al cambiamento di pagina ricarico i dati
   * @param i pagina cliccata
   */
  onIndexChange(i: number) {
    this.pagination_config.skip = (i - 1) * this.pagination_config.take;
    this.getData();
  }

  /**
   * Eliminazione della notifica e redirect verso la lista delle notifiche
   */
  onDeleteNotification() {
    this._notifications.deleteNotification(this.notificationSelected).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.showConfirmModal = false;
        this.getData();
      }
    })
  }

  openModal(id: string) {
    this.showConfirmModal = true;
    this.notificationSelected = id;
  }

  ngOnDestroy(){
    this.destroy$.next();
    this.destroy$.complete();
  }
}
