import { Component, inject, Input, OnInit } from '@angular/core';
import { ButtonConfig } from '../../../../core/interfaces/button-config';
import { BaseComponent } from '../../base/base.component';
import { FormControl, FormGroup } from '@angular/forms';
import { FormConfig } from '../../../../core/interfaces/form-config';
import { getButtonDynamicConfig } from '../../../utils/utilities';
import { ApplicationsService } from '../../../../core/services/applications.service';
import { AllocationsService } from '../../../../core/services/allocations.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-requests-contracts',
  templateUrl: './requests-contracts.component.html',
  styleUrl: './requests-contracts.component.scss'
})
export class RequestsContractsComponent extends BaseComponent implements OnInit {
  private readonly _applications = inject(ApplicationsService);
  private readonly _allocations = inject(AllocationsService);

  private readonly destroy$: Subject<void> = new Subject<void>();

  @Input() height: string = '';

  page_config: any = {
    title: '',
    no_data: ''
  }

  show_all_button_config: ButtonConfig = {
    type: 'link',
    label: 'Visualizza tutte',
    submit: false,
    callback: () => this.goTo('app/applications')
  };

  action_button_config: ButtonConfig = {
    type: 'primary',
    label: 'Apri',
    submit: false,
    shade: 'dark',
    callback: () => null
  }

  search_button_config: ButtonConfig = {
    type: 'primary',
    label: 'Cerca',
    submit: true,
    class: 'search-button',
    callback: () => null
  }

  data: any = [];

  filters_form: FormGroup = new FormGroup({
    search: new FormControl(null)
  });

  search_form_config: FormConfig = {
    formGroup: this.filters_form,
    form_class: 'd-flex gap-2',
    submit_function: () => this.onContractsFilter(),
    submit_button: this.search_button_config,
    controls: [
      { key: 'search', type: 'input', class: 'w-100', placeholder: 'Cerca utente per nome o codice fiscale' }
    ],
  };

  override ngOnInit(): void {
    super.ngOnInit();
    this.getPageConfig();
    this.getData();
  }

  /**
   * Retrieves data
   * @private
   */
  private getData(){
    if(this.isApplicant() || this.isCaf() || this.isAgency()){
      this.getApplications();
    } else if(this.isLessor() || this.isSyndicate()) {
      this.getAllocations();
    }
  }

  /**
   * Assegna le label da visualizzare in base al ruolo
   * @private
   */
  private getPageConfig(){
    if(this.isLessor() || this.isAssociation()) {
      this.page_config.title = 'I tuoi ultimi contratti';
      this.page_config.no_data = 'Non hai nessun contratto';
      this.show_all_button_config.callback = () => this.goTo('app/contracts');
    }
    else if(this.isSyndicate()) {
      this.page_config.title = 'I tuoi contratti';
      this.page_config.no_data = 'Nessun contratto presente';
      this.show_all_button_config.callback = () => this.goTo('app/contracts');
    }
    else if(this.isCaf() || this.isAgency()) {
      this.page_config.title = 'Le tue richieste';
      this.page_config.no_data = 'Nessuna richiesta presente';
    }
    else if(this.isApplicant()){
      this.page_config.title = 'Le tue richieste';
      this.page_config.no_data = 'Non hai inoltrato nessuna richiesta';
    }
  }

  /**
   * Retrieves data for applicant, caf and agency
   * @private
   */
  private getApplications(){
    this._applications.getApplications().pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.data = response.data.map((item: any) => ({
          id: item.id,
          status: item.status,
          label: this.isApplicant() ? item.code : `${item.applicant.name} ${item.applicant.surname}`
        }))
      }
    })
  }

  /**
   * Retrieves data for lessor and syndicate
   * @private
   */
  private getAllocations(filter: string = "", skip: number = 0, take: number = 10) {
    this._allocations.getAllocations({filter, skip, take}).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.data = response.data.map((item: any) => ({
          id: item.lease_offer_id,
          label: (!this.isLessor())?(item.lease_offer.code + " (" + item.lease_offer.user.name + " " + item.lease_offer.user.surname + ")"):(item.lease_offer.code)
        }));
      }
    })
  }

  /**
   * Filtra i dati
   * @private
   */
  private onContractsFilter(){
    this.getAllocations(this.filters_form.value["search"], 0, 10);
  }

  /**
   * Aggiunge dinamicamente la callback al bottone per accedere al dettaglio della richiesta
   * @param item
   */
  getActionButtonConfig(item: any) {
    let url = '/app/applications';
    if(this.isSyndicate()) {
      url = '/app/allocation-detail';
    } else if(this.isLessor()) {
      url = '/app/accomodations/detail';
    }
    return getButtonDynamicConfig(this.action_button_config, () => this.goTo(`${url}/${item.id}`));
  }
}
