import { inject, Injectable, TemplateRef } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FormattedResponse } from '../interfaces/formatted-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly _notification = inject(NzNotificationService);
  private readonly _http = inject(HttpClient);

  createNotification(type: string, title: string, content: string|TemplateRef<any>): void {
    this._notification.create(type, title, content);
  }

  getNotifications(params: any): Observable<FormattedResponse<any>> {
    return this._http.get<FormattedResponse<any>>(`${environment.api}/notifications`, {params});
  }

  deleteNotification(id: string): Observable<FormattedResponse<any>> {
    return this._http.delete<FormattedResponse<any>>(`${environment.api}/notifications/`+id);
  }
}
