import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrl: './info-page.component.scss'
})
export class InfoPageComponent {
  @Input() title: string = '';
  @Input() titleColor: string = 'var(--blue-900)';
  @Input() description: string = '';
  @Input() descriptionColor: string = '#525252';
  @Input() image: string = '';
}
