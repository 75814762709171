@switch (config.type){
  @case ('primary'){
    <button nz-button nzType="primary" class="primary"
            [type]="config.submit ? 'submit' : 'button'"
            (click)="!config.submit ? config.callback() : null"
            [ngClass]="[config.class ?? '', config.shade == 'dark' ? 'dark-primary' : (config.shade == 'white' ? 'white-primary' : '')]">

      @if(config.icon){
        <img [src]="config.icon" alt="icon">
      }

      @if(config.label){
        <span [innerText]="config.label"></span>
      }
    </button>
  }
  @case ('secondary'){
    <button nz-button class="secondary"
            [type]="config.submit ? 'submit' : 'button'"
            (click)="!config.submit ? config.callback() : null">
      <img [src]="config.icon" alt="icon">
    </button>
  }
  @case ('link'){
    <a class="link" (click)="config.callback()" [ngClass]="{'light-link': config.shade == 'light'}">{{config.label}}</a>
  }
  @case ('file'){
    <label for="file-upload" class="primary" [innerText]="config.label"></label>
    <input (change)="onUploadFiles.emit($event)" [multiple]="config.multiple" id="file-upload" type="file" [accept]="config.accepted_files"/>
  }
}
