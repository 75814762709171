import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  private readonly _router = inject(Router);
  private readonly _auth = inject(AuthService);

  canActivate = (): boolean => {
    if(this._auth.isAdmin()) {
      this._router.navigate(['/app/news']);
    }
    else {
      this._router.navigate(['/app/dashboard']);
    }

    return true;
  };
}
