<section>
  <span class="title" [innerText]="title"></span>

  @if(user_info){
    <div class="user-info">
      <app-user-image-info [name]="user_info.name + ' ' + user_info.surname"></app-user-image-info>
      <div class="info">
        <span [innerText]="user_info.tax_number"></span>
        <span [innerText]="user_info.birth_date | date: 'dd/MM/yyyy'"></span>
        <span [innerText]="user_info.phone"></span>
        <span [innerText]="user_info.email"></span>
      </div>
    </div>
  }

  @if(info){
    <span [innerText]="info"></span>
  }
</section>
