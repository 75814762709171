import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { fromLonLat } from 'ol/proj';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html'
})
export class MapComponent extends BaseComponent implements OnInit {
  @Input() style: any;
  @Input() coordinates: any;

  override ngOnInit() {
    super.ngOnInit();

    //Se l'utente non è locatore/associazione, genera coordinate casuali vicine con scostamento massimo di 150 metri
    if((!this.isLessor() && !this.isAssociation())){
      this.getRandomizedCoordinates(150);
    }

    const map = new Map({controls: []});
    map.setTarget('map');
    map.getInteractions().clear();
    map.setLayers([new TileLayer({source: new OSM()})]);
    map.setView(new View({center: fromLonLat(this.coordinates), zoom: 17}));
  }

  /**
   * Genera un punto casuale vicino alle coordinate originali
   * @param maxDistance distanza massima in metri
   * @private
   */
  private getRandomizedCoordinates(maxDistance: number): void {
    const [lon, lat] = [
      parseFloat(this.coordinates[0]),
      parseFloat(this.coordinates[1])
    ];

    // Calcola l'offset massimo in gradi
    const latOffset = maxDistance / 111121; // 1 grado di latitudine = 111,121 km
    const lonOffset = maxDistance / (111321 * Math.cos(lat * Math.PI / 180)); // 1 grado di longitudine = 111,321 km

    // Genera valori casuali per latitudine e longitudine
    const randomLat = lat + (Math.random() * 2 - 1) * latOffset;
    const randomLon = lon + (Math.random() * 2 - 1) * lonOffset;

    this.coordinates = [
      parseFloat(randomLon.toFixed(7)),
      parseFloat(randomLat.toFixed(7))
    ];
  }
}
