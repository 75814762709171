import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ButtonConfig } from '../../../core/interfaces/button-config';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss'
})
export class StepperComponent extends BaseComponent {
  @Input() templates: TemplateRef<any>[] = [];
  @Input() width: string = 'auto'
  @Input() final_step_buttons: Array<ButtonConfig> = [];
  @Output() onValidateStep: EventEmitter<number> = new EventEmitter();

  index: number = 0;

  back_config_button: ButtonConfig = {
    type: 'primary',
    label: 'Torna indietro',
    submit: false,
    class: 'white-border',
    callback: () => this.previousStep()
  }

  next_config_button: ButtonConfig = {
    type: 'primary',
    label: 'Prosegui',
    submit: false,
    shade: 'white',
    callback: () => this.validateStep()
  }

  /**
   * Prima di procedere al prossimo step, invia un evento al componente che lo implementa
   * @private
   */
  private validateStep(){
    this.onValidateStep.emit(this.index + 1);
  }

  /**
   * Step successivo, funzione chiamata dal componente che implementa lo stepper
   * @private
   */
  nextStep(): void {
    this.index++;
  }

  /**
   * Step precedente
   * @private
   */
  private previousStep(): void {
    this.index > 0 ? this.index-- : this.goTo('');
  }
}
