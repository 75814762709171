import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { ButtonConfig } from '../../../../core/interfaces/button-config';
import { AccomodationsService } from '../../../../core/services/accomodations.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-accomodations-widget',
  templateUrl: './accomodations-widget.component.html'
})
export class AccomodationsWidgetComponent extends BaseComponent implements OnInit, OnDestroy {
  private readonly _accomodations = inject(AccomodationsService);
  private readonly destroy$: Subject<void> = new Subject<void>();

  pagination_config = {
    skip: 0,
    take: 1
  }

  @Input() page_title: string = '';
  @Input() show_button: boolean = true;
  @Input() show_arrows: boolean = true;
  @Input() button_text: string = 'Visualizza';

  accomodation = null;
  total_accomodations = 0;

  show_all_button_config: ButtonConfig = {
    type: 'link',
    label: 'Visualizza tutti',
    submit: false,
    shade: 'light',
    callback: () => this.goTo('app/accomodations')
  };

  override ngOnInit() {
    super.ngOnInit();

    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getData();
  }

  /**
   * Recuper i dati in base alla tipologia di widget
   * @private
   */
  private getData(){
    let params = {};

    if(this.page_title == 'Alloggi simili'){
      params = {
        exclude_id: this.getCurrentId(),
        ...this.pagination_config
      }
    }
    else if(this.page_title == 'Alloggi'){
      params = this.pagination_config;
    }
    else if(this.page_title == 'I tuoi alloggi'){
      params = {
        dashboard_lessor: this.getCurrentId(),
        ...this.pagination_config
      }
    }

    this._accomodations.getWidgetAccomodations(params).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.accomodation = response.data.length > 0 ? response.data[0] : null;
        this.total_accomodations = response.total;
      }
    })
  }

  /**
   * Va alla card precedente/successiva
   * @param action previous/next
   */
  changeCard(action: string) {
    const skip = action == 'next' ? this.pagination_config.skip + 1 : this.pagination_config.skip - 1;
    this.pagination_config.skip =  skip * this.pagination_config.take;
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
