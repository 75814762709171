<div class="upload-box">
  <div class="upload">
    <div class="icon-text">

      @if(icon){
        <img [src]="icon" alt="pdf">
      }

      <div class="text">
        <span class="main-text" [innerText]="title"></span>

        @if(description){
          <span [innerText]="description"></span>
        }
      </div>
    </div>

    <app-button [config]="upload_button_config" (onUploadFiles)="onUploadFiles($event)"></app-button>
  </div>

  <div class="uploaded-files-container" [ngStyle]="style">
    @for(file of files; track file; let i = $index){
      <div class="uploaded-files">
        <div class="content">
          <ng-container [ngTemplateOutlet]="fileName" [ngTemplateOutletContext]="{item: file.name}"></ng-container>
          <img class="trash-icon" src="/icon/trash.svg" alt="trash" (click)="onTrashButtonClick(i)" onKeyDown="">
        </div>
      </div>
    }
  </div>
</div>

<ng-template #fileName let-item=item>
  <div class="filename">
    @if(accepted_files == '.pdf'){
      <img src="/icon/pdf.svg" alt="pdf">
    }
    <span [innerText]="item ?? files[modal_file_index!]?.name"></span>
  </div>
</ng-template>

<app-modal
  [isVisible]="modal_file_index !== null && showConfirmModal"
  [title]="'Sei sicuro di volere eliminare il documento?'"
  [content]="fileName"
  [actions]="['action', 'cancel']"
  [buttonLabel]="'Elimina'"
  (onAction)="onRemoveFile()"
  (onCancel)="closeModal()">
</app-modal>
