import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-location-facilities',
  templateUrl: './location-facilities.component.html',
  styleUrl: './location-facilities.component.scss'
})
export class LocationFacilitiesComponent implements OnChanges {
  @Input() location: string|null = null;
  @Input() displayed_facilities: any;

  displayed_facilities_keys: any;
  displayed_facilities_values: number = 0;

  facilities = [
    {
      key: 'bedrooms',
      icon: '/icon/grey-home.svg',
      value: null,
      type: 'number',
      text: 'stanza',
      plural_text: 'stanze'
    },
    {
      key: 'beds',
      icon: '/icon/bed.svg',
      value: null,
      type: 'number',
      text: 'posto letto',
      plural_text: 'posti letto'
    },
    {
      key: 'bathrooms',
      icon: '/icon/bath-tub.svg',
      value: null,
      type: 'number',
      text: 'bagno',
      plural_text: 'bagni'
    },
    {
      key: 'parking_slots',
      icon: '/icon/car.svg',
      value: null,
      type: 'number',
      text: 'posto auto',
      plural_text: 'posti auto'
    },
    {
      key: 'heating_system',
      icon: '/icon/heating-system.svg',
      value: null,
      type: 'boolean',
      text: 'Riscaldamenti'
    },
    {
      key: 'furniture',
      icon: '/icon/sofa.svg',
      value: null,
      type: 'boolean',
      text: 'Ammobiliata',
    }
  ]

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['displayed_facilities']){
      const displayed_facilities = changes['displayed_facilities'].currentValue;

      //recupero i dati da mostrare
      this.displayed_facilities_keys = Object.keys(displayed_facilities);

      //popolo i rispettivi valori con i dati in input
      for(let key in displayed_facilities){
        this.facilities.find(item => item.key == key)!.value = displayed_facilities[key];
        this.displayed_facilities_values += +displayed_facilities[key];
      }
    }
  }
}
