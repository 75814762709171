import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InterceptorService } from './core/helpers/interceptor.service';
import { SharedModule } from "./shared/shared.module";
import { it as dateFnsIt } from 'date-fns/locale';

//NgZorro
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NZ_DATE_LOCALE, NZ_I18N, it_IT } from 'ng-zorro-antd/i18n';
import { NzContentComponent, NzLayoutComponent, NzSiderComponent } from 'ng-zorro-antd/layout';

registerLocaleData(it);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NzSpinComponent,
    SharedModule,
    NzLayoutComponent,
    NzSiderComponent,
    NzContentComponent
  ],
  providers: [
    { provide: NZ_I18N, useValue: it_IT },
    { provide: NZ_DATE_LOCALE, useValue: dateFnsIt },
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'it' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
