import { Component, Input } from '@angular/core';
import { FormConfig } from '../../../core/interfaces/form-config';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import moment from 'moment';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class FormComponent {
  @Input() config!: FormConfig;
  @Input() submittedFromComponent: boolean = false;

  submitted: boolean = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '15rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: 'sora',
    fonts: [{class: 'sora', name: 'Sora'}],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        'fontSize',
        'backgroundColor',
        'toggleEditorMode',
        'insertImage',
        'insertVideo',
        'link',
        'unlink'
      ]
    ]
  };

  /**
   * Ritorna true se l'input è touched e il control è invalido, oppure se il form è submitted e il control è invalido
   * @param key chiave del FormControl
   */
  isControlInvalid(key: string): boolean {
    const touched_control = this.getFormControl(key)!.invalid && this.getFormControl(key)!.touched;
    const submitted_form = this.getFormControl(key)!.invalid && (this.submitted || this.submittedFromComponent);

    return touched_control || submitted_form;
  }

  /**
   * Ritorna il FormControl
   * @param key chiave del FormControl
   */
  getFormControl(key: string) {
    return (this.config.formGroup as FormGroup).get(key);
  }

  /**
   * Se è presente il parametro compareDates nella configurazione del FormControl esegue la comparazione delle date
   * @param compareDates
   */
  disabledDate= (compareDates: any) => (value: Date): boolean => {
    if(compareDates) {
      const otherDate: Date = this.config.formGroup.get(compareDates.key)?.value;

      if(otherDate){
        value.setHours(0, 0, 0, 0);
        otherDate.setHours(0, 0, 0, 0);

        const comparisons: { [key: string]: (a: number, b: number) => boolean } = {
          '>': (a, b) => a > b,
          '<': (a, b) => a < b,
          '>=': (a, b) => a >= b,
          '<=': (a, b) => a <= b
        };

        const compareFn = comparisons[compareDates.symbol];
        return compareFn(value.getTime(), otherDate.getTime());
      }
    }

    return false;
  };

  /**
   * Al focus out su un input, formatta il valore se presente una regola di formattazione nella configurazione
   * @param key chiave del control
   */
  onFocusOut(key: string): void {
    const { control, control_config } = this.findControlByKey(key);

    if(control_config?.onFocus && control?.value){
      let value = control.value;

      switch(control_config?.onFocus){
        case 'uppercase':
          value = value.toUpperCase();
          break;

        case 'titlecase':
          value = value.split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
          break;
      }

      control?.setValue(value, {emitEvent: false});
    }
  }

  /**
   * Al cambiamento di una data, formatta il valore se presente una regola di formattazione nella configurazione
   * @param key chiave del control
   */
  onDateChange(key: string) {
    const { control, control_config } = this.findControlByKey(key);

    if(control_config?.dateFormat && control?.value) {
      const formattedDate = moment(control.value).format(control_config.dateFormat);

      if(control.value !== formattedDate) {
        control.setValue(formattedDate, { emitEvent: false });
      }
    }
  }

  /**
   * Ritorna FormControl e configurazione
   * @param key chiave del control
   * @private
   */
  private findControlByKey(key: string) {
    return {
      control: this.config.formGroup.get(key),
      control_config: this.config.controls.find(item => item.key == key)
    }
  }

  /**
   * Gestisce i valori del gruppo di checkbox, in cui è possibile selezionare solo un'opzione
   * @param key
   * @param value
   */
  onCheckboxGroupChange(key: string, value: string) {
    const current_control = this.getFormControl(key)!;
    current_control.setValue(current_control.value === value ? null : value);
  }

  /**
   * Se il FormControl è obbligatorio e nella configurazione del form show_required_fields è true, mostro il simbolo *
   * @param key
   */
  isFieldRequired(key: string): string | null {
    const control = this.getFormControl(key);
    return this.config.show_required_fields && control?.validator?.({} as any)?.['required'] ? '*' : null;
  }
}
