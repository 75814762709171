import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormattedResponse } from '../interfaces/formatted-response';
import { environment } from '../../../environments/environment';
import { NO_LOADER } from '../helpers/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class AccomodationsService {
  private readonly _http = inject(HttpClient);

  getAccomodations(params: any): Observable<FormattedResponse<any>>{
    return this._http.get<FormattedResponse<any>>(`${environment.api}/accomodations`, { params });
  }

  getAccomodation(id: string): Observable<FormattedResponse<any>>{
    return this._http.get<FormattedResponse<any>>(`${environment.api}/accomodations/${id}`);
  }

  getWidgetAccomodations(params: any): Observable<FormattedResponse<any>>{
    return this._http.get<FormattedResponse<any>>(`${environment.api}/accomodations/widget`, {params, context: new HttpContext().set(NO_LOADER, true)});
  }
}
