import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonConfig } from '../../../core/interfaces/button-config';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrl: './upload-documents.component.scss'
})
export class UploadDocumentsComponent implements OnChanges {
  private readonly _notification = inject(NotificationService);

  @Input() style: any = null;
  @Input() multiple: boolean = true;
  @Input() icon: string|null = '/icon/pdf.svg';
  @Input() title: string = 'Carica i documenti';
  @Input() description: string|null = 'Puoi caricare soltanto file PDF non superiori a 50 MB';
  @Input() showConfirmModal: boolean = true;
  @Input() accepted_files: string = '.pdf';
  @Input() alreadyUploadedFiles: Array<any> = [];
  @Output() uploadedFiles: EventEmitter<Array<any>> = new EventEmitter();

  files: Array<any> = [];
  modal_file_index: number|null = null;

  upload_button_config: ButtonConfig = {
    type: 'file',
    label: 'Carica',
    submit: false,
    multiple: true,
    accepted_files: '.pdf',
    callback: () => null
  }

  /**
   * Chiude la modale di eliminazione del documento
   */
  closeModal(){
    this.modal_file_index = null;
  }

  /**
   * Elimina un documento
   */
  onRemoveFile(){
    this.files.splice(this.modal_file_index!, 1);
    this.uploadedFiles.emit(this.files);
    this.closeModal();
  }

  /**
   * Alla selezione di un file controlla la dimensione ed eventualmente mostra un errore
   * @param $event
   */
  onUploadFiles($event: Event) {
    const target = $event.target as HTMLInputElement;

    if(target.files){
      const maxSizeInBytes = 50 * 1024 * 1024;
      const files_array: File[] = Array.from(target.files);
      let files: Array<any> = [];
      let large_files: Array<string> = [];

      files_array.forEach(file => {
        if (file.size > maxSizeInBytes) {
          large_files.push(file.name)
        }
        else {
          files.push(file);
        }
      })

      if(large_files.length > 0){
        let error_content = 'Il file è superiore a 50 MB';

        if(this.multiple){
          error_content = `I seguenti file sono superiori a 50 MB: ${large_files.join(', ')}`;
        }

        this._notification.createNotification('error', 'Errore', error_content);
      }

      this.files = this.multiple ? this.files.concat(files) : files;
      this.uploadedFiles.emit(this.files);
    }
  }

  /**
   * Al click sul bottone per eliminare un documento mostra la modale di conferma solo se showConfirmModal è true
   * @param i indice nell'array files del file da eliminare
   */
  onTrashButtonClick(i: number) {
    this.modal_file_index = i;

    if(!this.showConfirmModal) {
      this.onRemoveFile();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['multiple']){
      this.upload_button_config.multiple = changes['multiple']?.currentValue;
    }

    if(changes['accepted_files']){
      this.upload_button_config.accepted_files = changes['accepted_files']?.currentValue;
    }

    if(changes['alreadyUploadedFiles']){
      this.files = changes['alreadyUploadedFiles']?.currentValue;
    }
  }
}
