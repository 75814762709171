import { Component } from '@angular/core';
import { getUser } from '../../../utils/user';

@Component({
  selector: 'app-last-access',
  templateUrl: './last-access.component.html',
  styleUrl: './last-access.component.scss'
})
export class LastAccessComponent {
  date: string = getUser()?.last_access;
}
