<section [ngStyle]="style">
  <div class="content">
    <div class="header">
      <span class="title" [innerText]="data?.text"></span>

      @if(data?.description){
        <span class="description" [innerText]="data?.description"></span>
      }
    </div>

    @if(data?.button_text){
      <app-button [config]="button_config"></app-button>
    }
  </div>
</section>
