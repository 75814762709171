import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonConfig } from '../../../core/interfaces/button-config';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-accomodation-card',
  templateUrl: './accomodation-card.component.html',
  styleUrl: './accomodation-card.component.scss'
})
export class AccomodationCardComponent extends BaseComponent implements OnChanges {
  @Input() accomodation: any = null;
  @Input() button_text: string = 'Visualizza';
  @Input() show_button: boolean = true;

  show_button_config: ButtonConfig = {
    type: 'primary',
    label: this.button_text,
    submit: false,
    shade: 'dark',
    callback: () => this.goTo(`/app/accomodations/detail/${this.accomodation?.id}`)
  };

  no_data_message: string = this.isLessor() || this.isAssociation() ? 'Non hai inserito nessun alloggio' : 'Nessun alloggio presente';

  ngOnChanges(changes: SimpleChanges): void {
    this.show_button_config.label = changes['button_text']?.currentValue ?? this.button_text;
  }
}
