<section>
  <article [ngStyle]="{'width': width}">
    <ng-container [ngTemplateOutlet]="templates[index]"></ng-container>
  </article>

  <div class="stepper">
    <div class="dots">
      @for(dot of templates; track dot; let i = $index){
        <div class="dot" [ngClass]="{
          'is-active': i == index,
          'is-near-active': i == index + 1 || i == index - 1,
          'is-far-from-active': i == index + 2 || i == index - 2
        }"></div>
      }
    </div>
    <div class="buttons">
      @if(index == templates.length - 1){
        @for(button of final_step_buttons; track button){
          <app-button [config]="button"></app-button>
        }
      }

      @if(index != templates.length - 1){
        <app-button [config]="back_config_button"></app-button>
        <app-button [config]="next_config_button"></app-button>
      }
    </div>
  </div>
</section>
