import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent {
  @Input() icon: string = '';
  @Input() size: string = '2rem';
  @Input() icon_size: string|null = '2rem';
  @Input() background: string = 'var(--blue-600)';
}
