<article class="page-header">

  @if(path){
    <div class="back-container" (click)="goTo(path)" onKeyDown="">
      <img src="/icon/buttons/back.svg" alt="back">
      <span>Torna indietro</span>
    </div>
  }

  <span [innerText]="page_title"></span>
</article>

