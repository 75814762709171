<div class="no-data">
  <div class="items">
    <div class="icon">
      <img src="/icon/sad-face.svg" alt="sad-face">
    </div>

    <div class="text">
      <span [innerText]="text"></span>
    </div>
  </div>
</div>
