import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormattedResponse } from '../interfaces/formatted-response';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  private readonly _http = inject(HttpClient);

  getApplications(params: any = null): Observable<FormattedResponse<any>>{
    return this._http.get<FormattedResponse<any>>(`${environment.api}/applications`, { params });
  }
}
