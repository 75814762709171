import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonConfig } from '../../../core/interfaces/button-config';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnChanges {
  @Input() isVisible: boolean = false;
  @Input() title: string = '';
  @Input() content: any;
  @Input() actions: Array<string> = [];
  @Input() closable: boolean = false;
  @Input() buttonLabel: string|null = null;

  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onAction: EventEmitter<void> = new EventEmitter();

  action_button_config: ButtonConfig = {
    type: 'primary',
    label: '',
    submit: false,
    shade: 'dark',
    callback: () => this.onAction.emit()
  }

  cancel_button_config: ButtonConfig = {
    type: 'primary',
    label: 'Annulla',
    submit: false,
    shade: 'white',
    callback: () => this.onCancel.emit()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['buttonLabel']){
      this.action_button_config.label = changes['buttonLabel']!.currentValue;
    }
  }
}
