import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-application-recap',
  templateUrl: './application-recap.component.html',
  styleUrl: './application-recap.component.scss'
})
export class ApplicationRecapComponent {
  @Input() type: string = 'application';
  @Input() application: any = null;
  @Input() style: any = null;
  @Input() displayedData: Array<string> = ['name', 'tax_number', 'birth_date', 'phone', 'email'];
}
