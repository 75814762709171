@if(config.formGroup){
  <form (submit)="config.submit_function ? config.submit_function() : null; submitted = true" [formGroup]="config.formGroup" [class]="config.form_class">
    @for (control of config.controls; track control){

      <div class="input-box" [class]="control.class">

        @if(control.label && !['checkbox', 'checkbox-group'].includes(control.type)){
          <label [for]="control.key" [ngClass]="config.label_class ?? ''">
            {{control.label}} {{isFieldRequired(control.key)}}

            @if(control.info){
              <span
                class="cursor-pointer"
                nz-icon nzType="info-circle" nzTheme="outline"
                nz-tooltip [nzTooltipTitle]="control.info" nzTooltipPlacement="rightTop">
              </span>
            }
          </label>
        }

        @switch (control.type){
          @case('input'){
            <input
              (focusout)="onFocusOut(control.key)"
              [ngClass]="{'invalid': isControlInvalid(control.key)}" nz-input
              [type]="control.input_type ?? 'text'"
              [formControlName]="control.key"
              [placeholder]="control.placeholder ?? ''"/>
          }
          @case('input-group'){
            <nz-input-group [nzAddOnAfter]="control.afterAddOn">
              <input [ngClass]="{'invalid': isControlInvalid(control.key)}" type="text" nz-input [formControlName]="control.key"/>
            </nz-input-group>
          }
          @case('date'){
            <nz-date-picker
              [nzDisabled]="control.disabled"
              [nzDisabledDate]="disabledDate(control.compareDates)"
              [ngClass]="{'invalid': isControlInvalid(control.key)}"
              [formControlName]="control.key"
              [nzPlaceHolder]="[]" nzFormat="dd/MM/yyyy"
              (ngModelChange)="onDateChange(control.key)">
            </nz-date-picker>
          }
          @case('select'){
            <nz-select [ngClass]="{'invalid': isControlInvalid(control.key)}" [formControlName]="control.key" [nzAllowClear]="control.allowClear ?? true" [nzPlaceHolder]="control.placeholder ?? null">
              @for(option of control.options; track option.value){
                <nz-option [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
              }
            </nz-select>
          }
          @case('textarea'){
            <textarea rows="4" [ngClass]="{'invalid': isControlInvalid(control.key)}" nz-input [formControlName]="control.key" [placeholder]="control.placeholder ?? ''"></textarea>
          }
          @case('editor'){
            <angular-editor [ngClass]="{'invalid': isControlInvalid(control.key)}" [formControlName]="control.key" [config]="editorConfig"></angular-editor>
          }
          @case('checkbox'){
            <label class="checkbox-label" nz-checkbox [formControlName]="control.key" [for]="control.key">
              {{control.label}}
            </label>
          }
          @case('checkbox-group'){
            <input class="d-none" type="checkbox" [formControlName]="control.key"/>

            <div class="d-flex gap-4">
              @for(option of control.options; track option.value){
                <label class="custom-checkbox checkbox-label gap-1">
                  <input class="d-none" type="checkbox" [value]="option.value" (change)="onCheckboxGroupChange(control.key, option.value)" [checked]="getFormControl(control.key)?.value === option.value">
                  <span class="checkmark" [ngClass]="{'invalid': isControlInvalid(control.key)}"></span>
                  <span>{{option.label}}</span>
                </label>
              }

              <span class="checkbox-label">{{isFieldRequired(control.key)}}</span>
            </div>
          }
        }

        <div [ngClass]="{'invalid-box': config.show_error_gap}">
          @if(isControlInvalid(control.key)){
            <span class="invalid-text">
              @if(getFormControl(control.key)?.hasError('required')){
                Questo campo è obbligatorio
              }
              @else if(getFormControl(control.key)?.hasError('email')){
                Questo campo deve essere un'email valida
              }
              @else {
                Formato non corretto
              }
            </span>
          }
        </div>

      </div>
    }

    @if(config.submit_button){
      <app-button [config]="config.submit_button"></app-button>
    }
  </form>
}


