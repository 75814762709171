<div class="location-facilities">
  @if(location){
    <span class="location" [innerText]="location"></span>
  }

  <div class="facilities">
    @if(displayed_facilities_values > 0) {
      @for(facility of facilities; track facility.icon){
        @if(displayed_facilities_keys.includes(facility.key) && facility.value! > 0){
          <div class="facility">
            <div class="image">
              <img [src]="facility.icon" alt="home">
            </div>

            @if(facility.type == 'number'){
              <span>{{ facility.value }}</span>
              <span>{{ facility.value! > 1 ? facility.plural_text : facility.text}}</span>
            }
            @else {
              <span>{{facility.text}}</span>
            }
          </div>
        }
      }
    }
    @else {
      <span class="no-data" [innerText]="'Nessun campo compilato'"></span>
    }
  </div>
</div>
