<div class="box">
  <div class="content">
    <div class="info">
      <div class="header">
        <img [src]="user_info?.icon" alt="icon">
        <span>Ciao {{user_info?.name}}!</span>
      </div>

      <div class="additional-info">
        @if(user_info?.name || user_info?.surname){
          <span class="user-name">{{user_info?.name}} {{user_info?.surname}}</span>
        }

        @if(user_info?.birth_date){
          <span [innerText]="user_info?.birth_date | date: 'dd/MM/yyyy'"></span>
        }

        @if(user_info?.tax_number){
          <span [innerText]="user_info?.tax_number"></span>
        }

        @if(user_info?.email){
          <span [innerText]="user_info?.email"></span>
        }
      </div>
    </div>
  </div>
</div>
