<section [ngClass]="{'w-100': true, 'bg-white': isArchive}">
  @if(isArchive) {
    <div class="w-100 d-flex align-items-center justify-content-between">
      <app-page-header [page_title]="'Notifiche'" class="mb-2"></app-page-header>
      <button (click)="getData(true)" onKeyDown="getData(true)" class="notification-button ml-auto">
        Ordina <img src="/icon/lsicon_sort-filter-outline.svg" alt="Ordina">
      </button>
    </div>
  } @else {
    <button (click)="goTo('app/notifications-archive')" onKeyDown="goTo('app/notifications-archive')" class="notification-button">Notifiche</button>
  }
  <div [ngClass]="{'content': true, 'archive-section': isArchive}">
    @if(notifications === null || notifications.length === 0) {
      <div class="no-notifications">Nessuna notifica disponibile</div>
    } @else {
      @for(notification of notifications; track notification.id) {
        <div class="notification-box" [ngClass]="{'bg-blue-50': isArchive}" (click)="goTo(notification?.type.link + ((notification?.type.link != '#')?(notification?.element_id):('')))" onKeyDown="">
          <div class="item">
            <app-icon [icon]="'/icon/bell.svg'"></app-icon>
            <div class="w-100 d-flex align-items-center justify-content-between">
              <a class="description align-self-center" [ngClass]="{'w-auto': isArchive}">{{notification?.message}}</a>
              @if(isArchive) {
                <button (click)="openModal(notification.id)" onKeyUp="openModal(notification.id)" class="notification-button ml-auto">
                  <img src="/icon/trash_red.svg" alt="Ordina">
                </button>
              }
            </div>
          </div>
        </div>
      }
    }
    @if(isArchive) {
      <app-pagination [nzTotal]="total_notifications" [nzPageSize]="pagination_config.take" (indexChange)="onIndexChange($event)"></app-pagination>
    }
  </div>
</section>

<app-modal
  [isVisible]="showConfirmModal"
  [title]="'Sei sicuro di volere eliminare la notifica?'"
  [actions]="['action', 'cancel']"
  [buttonLabel]="'Elimina'"
  (onAction)="onDeleteNotification()"
  (onCancel)="showConfirmModal = false">
</app-modal>
