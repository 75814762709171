import { Component, OnInit } from '@angular/core';
import { UserInfo } from '../../../../core/interfaces/user-info';
import { getUser } from '../../../utils/user';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss'
})
export class UserInfoComponent implements OnInit {
  user_info: UserInfo|null = null;

  ngOnInit(): void {
    const user = getUser();

    if(user){
      const role = user.roles.length > 0 ? user.roles[0]['name'] : null;

      this.user_info = {
        icon: `/roles/${role}.png`,
        name: user?.name,
        surname: user?.surname,
        email: user?.email,
        birth_date: user?.birth_date,
        tax_number: user?.tax_number
      }
    }
  }
}
