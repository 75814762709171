import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ButtonConfig } from '../../../core/interfaces/button-config';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @Input() title: string = '';
  @Input() show_all_button_config: ButtonConfig|null = null;
  @Input() clickableLeft: boolean = true;
  @Input() clickableRight: boolean = true;
  @Input() show_arrows: boolean = false;
  @Input() content!: TemplateRef<any>;

  @Output() onCardChange = new EventEmitter();
}
