import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-notification-error-template',
  templateUrl: './notification-error-template.component.html'
})
export class NotificationErrorTemplateComponent {
  @Input() message: string = '';
  @Input() errors: string[] = [];
}
