<div class="card row">
  @if(accomodation){
    <div class="col-6 image-box">
      <img [src]="accomodation.image" alt="">
    </div>
    <div class="col-6 info-box">
      @if(isLessor() || isAssociation()){
        <ng-container [ngTemplateOutlet]="badge" [ngTemplateOutletContext]="{item: accomodation.status}"></ng-container>
      }

      <div class="info" [ngStyle]="{'gap': (isLessor() || isAssociation()) ? '1rem' : '1.5rem', 'padding': isLessor() ? '0.5rem 1.5rem' : '1.5rem 1.5rem 0.5rem'}">
        <div class="title">
          <span class="type" [innerText]="accomodation.type"></span>
          <span class="location" [innerText]="(isLessor() || isAssociation()) ? accomodation.location : null"></span>
        </div>

        <div class="facilities">
          @if(accomodation.bedrooms) {
            <div class="facility">
              <span [innerText]="accomodation.bedrooms"></span>
              <img src="/icon/bed.svg" alt="bed">
            </div>
          }

          @if(accomodation.bathrooms) {
            <div class="facility">
              <span [innerText]="accomodation.bathrooms"></span>
              <img src="/icon/bath-tub.svg" alt="bath-tub">
            </div>
          }

          @if(accomodation.parking_space) {
            <div class="facility">
              <span [innerText]="accomodation.parking_space"></span>
              <img src="/icon/car.svg" alt="car">
            </div>
          }
        </div>
      </div>

      @if(show_button){
        <div class="info-button">
          <app-button [config]="show_button_config"></app-button>
        </div>
      }
    </div>
  }
  @else {
    <div class="no-data-box">
      <app-no-data [text]="no_data_message"></app-no-data>
    </div>
  }
</div>


<ng-template #badge let-item=item>
  <div class="status">
    @switch (item){
      @case('assigned'){
        <div class="green badge">
          <span class="green">Assegnato</span>
        </div>
      }
      @case('approved'){
        <div class="yellow badge">
          <span class="yellow">Approvato</span>
        </div>
      }
      @case('on_hold'){
        <div class="blue badge">
          <span class="blue">In attesa di approvazione</span>
        </div>
      }
      @case('rejected'){
        <div class="red badge">
          <span class="red">Rifiutato</span>
        </div>
      }
    }
  </div>
</ng-template>
