@if(isLoading){
  <div class="loader-container">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
}

<nz-layout [ngStyle]="{background: backgroundColor}">
  @if(isSidebarVisible){
    <nz-sider class="sider" nzTheme="light">
      <app-sidebar></app-sidebar>
    </nz-sider>
  }

  <div class="content" [ngClass]="{'margin-left': isSidebarVisible}">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</nz-layout>
