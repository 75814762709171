import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, ReplaySubject } from 'rxjs';
import { Location } from '@angular/common';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html'
})
export class BaseComponent implements OnInit {
  public readonly _router = inject(Router);
  public _auth = inject(AuthService);
  public _location = inject(Location);
  private readonly _route = inject(ActivatedRoute);

  current_route: ReplaySubject<string> = new ReplaySubject();

  ngOnInit() {
    this.onRouteChange();
  }

  /**
   * Ascolta i cambiamenti del routing e aggiorna il ReplaySubject
   */
  onRouteChange(){
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: route => {
        this.current_route.next(route.url);
      }
    })
  }

  /**
   * Va alla rotta definita
   * @param route url
   */
  goTo(route: string, external: boolean = false) {
    this._router.navigate([route]);
  }

  /**
   * Va alla rotta definita aprendo una nuova pagina del browser
   * @param route url
   */
  goToExternal(route: string) {
    window.open(route, '_blank');
  }

  /**
   * Ritorna l'id presente nell'url
   */
  getCurrentId() {
    return this._route.snapshot.params['id'];
  }

  /**
   * Torna indietro
   */
  goBack(){
    this._location.back();
  }

  /**
   * Funzioni di utility per recuperare il ruolo dell'utente loggato
   */
  isApplicant() {
    return this._auth.isApplicant();
  }

  isLessor() {
    return this._auth.isLessor();
  }

  isSyndicate() {
    return this._auth.isSyndicate();
  }

  isCaf() {
    return this._auth.isCaf();
  }

  isAgency() {
    return this._auth.isAgency();
  }

  isAdmin() {
    return this._auth.isAdmin();
  }

  isIntegration() {
    return this._auth.isIntegration();
  }

  isAssociation() {
    return this._auth.isAssociation();
  }
}
