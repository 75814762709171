import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { LoaderService } from './core/helpers/loader.service';
import { BaseComponent } from './shared/components/base/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponent implements OnInit {
  private readonly loaderService = inject(LoaderService);
  private readonly cdr = inject(ChangeDetectorRef);

  isLoading: boolean = false;
  isSidebarVisible: boolean = false;
  backgroundColor: string = '';

  pagesWithoutSidebar: Array<string> = ['auth', 'guide', 'accomodation-form', 'allocation-form'];
  pagesWithBlueBackground: Array<string> = ['accomodation-form', 'allocation-form'];

  override ngOnInit(): void {
    super.ngOnInit();

    this.current_route.subscribe({
      next: current_route => {

        //gestisco la presenza della sidebar
        for(let page of this.pagesWithoutSidebar) {
          this.isSidebarVisible = !current_route.includes(page);
          if(!this.isSidebarVisible) break;
        }

        //gestisco il colore di sfondo
        for(let page of this.pagesWithBlueBackground) {
          this.backgroundColor = current_route.includes(page) ? 'var(--blue-800)' : 'white';
          if(this.backgroundColor != 'white') break;
        }
      }
    })

    this.loaderService.loading$.subscribe({
      next: value => {
        this.isLoading = value;
        this.cdr.detectChanges(); //serve per evitare l'errore ExpressionChangedAfterItHasBeenCheckedError
      }
    })
  }
}
