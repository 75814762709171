import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Router } from '@angular/router';
import { SUCCESS_MESSAGE } from '../helpers/interceptor.service';
import { environment } from '../../../environments/environment';
import { LoginRequest, LoginResponse } from '../interfaces/login-request';
import { FormattedResponse } from '../interfaces/formatted-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _http = inject(HttpClient);
  private readonly _router = inject(Router);

  private readonly api_url = `${environment.api}/auth`;

  /**
   * Effettua la login con le credenziali
   */
  login(params: LoginRequest): Observable<FormattedResponse<LoginResponse>> {
    return this._http.post<FormattedResponse<LoginResponse>>(`${this.api_url}/login`, params, {
      context: new HttpContext().set(SUCCESS_MESSAGE, false)
    });
  }

  /**
   * Effettua la registrazione
   */
  register(params: LoginRequest): Observable<FormattedResponse<LoginResponse>> {
    return this._http.post<FormattedResponse<LoginResponse>>(`${this.api_url}/register`, params);
  }

  /**
   * Effettua la logout ed elimina gli elementi presenti nel localStorage
   */
  logout(){
    this._http.get<FormattedResponse<null>>(`${this.api_url}/logout`).subscribe({
      next: () => {
        localStorage.clear();
        this._router.navigate(['auth/login']);
      }
    });
  }

  /**
   * Effettua la me
   */
  me(): Observable<FormattedResponse<any>> {
    return this._http.get<FormattedResponse<any>>(`${this.api_url}/me`);
  }

  /**
   * Ritorna true se l'utente è autenticato
   */
  isAuthenticated(): boolean{
    return !!localStorage.getItem('csrf_token');
  }

  /**
   * Ritorna l'array dei ruoli dell'utente loggato
   */
  getUserRoles(): Array<string>{
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;
    return user?.roles.map((item: any) => item.name) ?? [];
  }

  /**
   * Ritorna true se l'utente ha ruolo Sindacato
   */
  isSyndicate(){
    const user_roles = this.getUserRoles();
    return user_roles?.some(() => user_roles.includes('syndicate'));
  }

  /**
   * Ritorna true se l'utente ha ruolo Locatore
   */
  isLessor(){
    const user_roles = this.getUserRoles();
    return user_roles?.some(() => user_roles.includes('lessor'));
  }

  /**
   * Ritorna true se l'utente ha ruolo Richiedente
   */
  isApplicant(){
    const user_roles = this.getUserRoles();
    return user_roles?.some(() => user_roles.includes('applicant'));
  }

  /**
   * Ritorna true se l'utente ha ruolo CAF
   */
  isCaf(){
    const user_roles = this.getUserRoles();
    return user_roles?.some(() => user_roles.includes('caf'));
  }

  /**
   * Ritorna true se l'utente ha ruolo Agenzia
   */
  isAgency(){
    const user_roles = this.getUserRoles();
    return user_roles?.some(() => user_roles.includes('real_estate_agency'));
  }

  /**
   * Ritorna true se l'utente ha ruolo Admin
   */
  isAdmin(){
    const user_roles = this.getUserRoles();
    return user_roles?.some(() => user_roles.includes('administrator'));
  }

  /**
   * Ritorna true se l'utente ha ruolo Integration
   */
  isIntegration(){
    const user_roles = this.getUserRoles();
    return user_roles?.some(() => user_roles.includes('integration'));
  }

  /**
   * Ritorna true se l'utente ha ruolo Association
   */
  isAssociation(){
    const user_roles = this.getUserRoles();
    return user_roles?.some(() => user_roles.includes('association'));
  }
}
