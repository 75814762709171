<div class="accomodation-card" [ngStyle]="style">
  <div class="applicant-info">
    <div class="accomodation">
      <app-icon [icon]="'/icon/home.svg'" [size]="'3.125rem'"></app-icon>
      <div class="text">
        <span class="main-text" [innerText]="application?.code"></span>
        <span>Richiesta inoltrata giorno {{application?.application_date | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div>

    <div class="applicant">
      <div class="text">

        @if(displayedData.includes('name')){
          <span class="applicant-name" [innerText]="application?.applicant.name + ' ' + application?.applicant.surname"></span>
        }

        @if(displayedData.includes('tax_number')){
          <span [innerText]="application?.applicant.tax_number"></span>
        }

        @if(displayedData.includes('birth_date')){
          <span [innerText]="application?.applicant.birth_date | date: 'dd/MM/yyyy'"></span>
        }

        @if(displayedData.includes('phone')){
          <span [innerText]="application?.applicant.phone"></span>
        }

        @if(displayedData.includes('email')){
          <span [innerText]="application?.applicant.email"></span>
        }
      </div>
    </div>
  </div>
  <app-status-badge [type]="type" [status]="application?.status"></app-status-badge>
</div>
