import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-image-info',
  templateUrl: './user-image-info.component.html',
  styleUrl: './user-image-info.component.scss'
})
export class UserImageInfoComponent {
  @Input() name: string = '';
  @Input() info: string = '';
}
