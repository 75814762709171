@let has_data = data.length > 0;

<section [ngStyle]="{'height': height}">
  @if(isSyndicate()){
    <div class="syndicate-widget-header">
      <span [innerText]="page_config.title"></span>
      <div class="form">
        <app-form [config]="search_form_config"></app-form>
      </div>
    </div>
  }
  @else {
    <div class="widget-header">
      <span [innerText]="page_config.title"></span>

      @if(has_data){
        <app-button [config]="show_all_button_config"></app-button>
      }
    </div>
  }

  <div class="content">

    @if(has_data){
      <div class="data">
        @for(item of data; track item.id){
          <div class="item">
            <div class="info">
              <div class="icon-label">
                <div>
                  @if(isLessor() || isAssociation()){
                    <app-icon [icon]="'/icon/home.svg'"></app-icon>
                  }
                  @else {
                    <img src="/icon/man.png" alt="role">
                  }
                  <span>{{item.label}}</span>
                </div>
              </div>

              @if(item.status && !isLessor() && !isAssociation()){
                <app-status-badge [status]="item.status" [type]="'application'"></app-status-badge>
              }
            </div>

            <app-button [config]="getActionButtonConfig(item)"></app-button>
          </div>
        }
      </div>
    }
    @else {
      <app-no-data [text]="page_config.no_data"></app-no-data>
    }
  </div>
</section>
