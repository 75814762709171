import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonConfig } from '../../../core/interfaces/button-config';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() config!: ButtonConfig;
  @Output() onUploadFiles: EventEmitter<any> = new EventEmitter();
}
