import { Component, Input } from '@angular/core';
import { Tab } from '../../../core/interfaces/tab';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
  @Input() tabs!: Array<Tab>;
  @Input() selectedIndex: number = 0;

  /**
   * Gestisce l'icona da mostrare in base alla tab selezionata
   * @param index
   */
  onTabChange(index: number) {
    this.tabs.forEach((tab: Tab, i: number) => {
      if(i == index){
        tab.icon = tab.icon.replace('.svg', '-active.svg');
      }
      else if(tab.icon.includes('-active.svg')){
        tab.icon = tab.icon.replace('-active.svg', '.svg');
      }
    })
  }
}
