import {Component, Input} from '@angular/core';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent extends BaseComponent {
  @Input() path: string = '';
  @Input() page_title: string = '';
}
