import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { SidebarButtonConfig } from '../../../core/interfaces/button-config';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent extends BaseComponent implements OnInit{

  upperButtonsConfig: Array<SidebarButtonConfig> = [
    {
      label: 'Dashboard',
      icon: '/icon/sidebar/dashboard.svg',
      callback:  () => this.goTo('app/dashboard'),
      selectionKeyword: 'dashboard',
      selected: false,
      showOption: !this.isAdmin()
    },
    {
      label: 'Le mie richieste',
      icon: '/icon/sidebar/email.svg',
      callback:  () => this.goTo('app/applications'),
      selectionKeyword: 'applications',
      selected: false,
      showOption: this.isApplicant() || this.isCaf() || this.isAgency()
    },
    {
      label: 'Carica alloggio',
      icon: '/icon/sidebar/email.svg',
      callback:  () => this.goTo('app/accomodation-form'),
      selected: false,
      showOption: this.isLessor() || this.isAssociation()
    },
    {
      label: this.isLessor() || this.isAssociation() ? 'I tuoi alloggi' : 'Lista alloggi',
      icon: '/icon/sidebar/list.svg',
      callback:  () => this.goTo('app/accomodations'),
      selectionKeyword: 'accomodations',
      selected: false,
      showOption: !this.isAdmin() && !this.isSyndicate()
    },
    {
      label: 'Crea assegnazione',
      icon: '/icon/sidebar/contract.svg',
      callback:  () => this.goTo('app/allocation-form'),
      selected: false,
      showOption: this.isSyndicate()
    },
    {
      label: 'News',
      icon: '/icon/sidebar/news.svg',
      callback:  () => this.goTo('app/news'),
      selectionKeyword: 'news',
      selected: false,
      showOption: true
    }
  ]

  lowerButtonsConfig: Array<SidebarButtonConfig> = [
    {
      label: 'Account',
      icon: '/icon/sidebar/account.svg',
      callback:  () => null,
      selectionKeyword: 'account',
      selected: false,
      showOption: true
    },
    {
      label: 'Logout',
      icon: '/icon/sidebar/logout.svg',
      callback:  () => this._auth.logout(),
      selected: false,
      showOption: true
    }
  ]

  override ngOnInit(): void {
    super.ngOnInit();

    this.checkButtonSelection(this._router.url);

    //Ascolto gli eventi di navigazione e gestisco l'illuminazione delle varie opzioni
    this.current_route.subscribe({
      next: route => {
        this.checkButtonSelection(route);
      }
    })
  }

  /**
   * In base alla rotta modifica illuminazione e icona dell'opzione
   * @param route
   */
  private checkButtonSelection(route: string){
    this.upperButtonsConfig.forEach(button => {
      button.selected = button.selectionKeyword ? this.isButtonSelected(route, button.selectionKeyword) : false;

      if((button.selected && !button.icon?.includes('-active.svg'))){
        button.icon = button.icon!.replace('.svg', '-active.svg');
      }
      else if(!button.selected){
        button.icon = button.icon!.replace('-active.svg', '.svg');
      }
    });

    this.lowerButtonsConfig.forEach(button => {
      button.selected = this.isButtonSelected(route, button.selectionKeyword!);
    });
  }

  /**
   * Ritorna true se l'url corrente contiene la keyword nel path
   * @param url url corrente
   * @param path keyword per far illuminare il bottone
   * @private
   */
  private isButtonSelected(url: string, path: string): boolean {
    const defaultPath = path == 'dashboard' && url == '/';
    return url.includes(path) || defaultPath;
  }
}
