<app-card
  [title]="page_title"
  [show_all_button_config]="show_button && total_accomodations > 0 ? show_all_button_config : null"
  [show_arrows]="show_arrows"
  [clickableLeft]="pagination_config.skip != 0"
  [clickableRight]="pagination_config.skip != total_accomodations - 1 && total_accomodations > 0"
  [content]="cardTemplate"
  (onCardChange)="changeCard($event)">
</app-card>

<ng-template #cardTemplate>
  <app-accomodation-card class="h-100" [accomodation]="accomodation" [button_text]="button_text" [show_button]="show_button"></app-accomodation-card>
</ng-template>
