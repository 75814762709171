<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="title"
  [nzContent]="content"
  [nzWidth]="'40rem'"
  [nzClosable]="closable"
  [nzCentered]="true"
  (nzOnCancel)="onCancel.emit()">

  <div class="footer" *nzModalFooter>
    @if(actions.includes('action')){
      <app-button [config]="action_button_config"></app-button>
    }

    @if(actions.includes('cancel')){
      <app-button [config]="cancel_button_config"></app-button>
    }
  </div>
</nz-modal>
