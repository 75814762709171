<section>
    <div class="widget-header">
      <span class="light" [innerText]="title"></span>

      @if(show_all_button_config?.label){
        <app-button [config]="show_all_button_config!"></app-button>
      }
    </div>

    <div class="content">
      <ng-container
        [ngTemplateOutlet]="arrow"
        [ngTemplateOutletContext]="{direction: 'left', clickable: clickableLeft}">
      </ng-container>

      <ng-container [ngTemplateOutlet]="content"></ng-container>

      <ng-container
        [ngTemplateOutlet]="arrow"
        [ngTemplateOutletContext]="{direction: 'right', clickable: clickableRight}">
      </ng-container>
    </div>
</section>

<ng-template #arrow let-direction='direction' let-clickable='clickable'>
  @if(show_arrows){
    <div class="arrow-box">
      @if(clickable) {
        <img class="arrow"
          (click)="clickable ? onCardChange.emit(direction == 'left' ? 'previous' : 'next') : null" onKeyDown=""
          [src]="'/icon/buttons/' + direction +'.svg'" alt="left">
      }
    </div>
  }
</ng-template>
