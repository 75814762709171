<nz-tabset (nzSelectedIndexChange)="onTabChange($event)" [nzSelectedIndex]="selectedIndex">
  @for (tab of tabs; track tab.name) {
    <nz-tab [nzTitle]="titleTemplate">
      <ng-template #titleTemplate>
        <img [src]="tab.icon" alt="icon">
        {{ tab.name }}
      </ng-template>
      <ng-container *ngTemplateOutlet="tab.template"></ng-container>
    </nz-tab>
  }
</nz-tabset>
