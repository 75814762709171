import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from './shared/components/redirect/redirect.component';
import { RedirectGuard } from './core/guards/redirect-guard.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'app', loadChildren: () => import('../app/modules/features/features.module').then(m => m.FeaturesModule) },
  { path: '', component: RedirectComponent, canActivate: [RedirectGuard], pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
