import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

//NgZorro
import { NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzInputDirective, NzInputGroupComponent } from 'ng-zorro-antd/input';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzPaginationComponent } from "ng-zorro-antd/pagination";
import { NzModalComponent, NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabComponent, NzTabSetComponent } from 'ng-zorro-antd/tabs';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzOptionComponent, NzSelectComponent } from "ng-zorro-antd/select";
import { NzCheckboxComponent } from "ng-zorro-antd/checkbox";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

//Componenti
import { SidebarButtonComponent } from './components/sidebar-button/sidebar-button.component';
import { FormComponent } from './components/form/form.component';
import { BaseComponent } from './components/base/base.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ButtonComponent } from './components/button/button.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { UserInfoComponent } from './components/widgets/user-info/user-info.component';
import { LastAccessComponent } from './components/widgets/last-access/last-access.component';
import { NotificationsComponent } from './components/widgets/notifications/notifications.component';
import { ActionBoxComponent } from './components/widgets/action-box/action-box.component';
import { RequestsContractsComponent } from './components/widgets/requests-contracts/requests-contracts.component';
import { IconComponent } from './components/icon/icon.component';
import { AccomodationsWidgetComponent } from './components/widgets/accomodations-widget/accomodations-widget.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { AccomodationCardComponent } from './components/accomodation-card/accomodation-card.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { MapComponent } from './components/map/map.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { ModalComponent } from './components/modal/modal.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { LocationFacilitiesComponent } from './components/location-facilities/location-facilities.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { InfoPageComponent } from './components/info-page/info-page.component';
import { UserImageInfoComponent } from './components/user-image-info/user-image-info.component';
import { ApplicationRecapComponent } from './components/application-recap/application-recap.component';
import { LightblueBoxComponent } from './components/lightblue-box/lightblue-box.component';
import { NotificationErrorTemplateComponent } from './components/notification-error-template/notification-error-template.component';
import { CardComponent } from './components/card/card.component';

@NgModule({
  declarations: [
    BaseComponent,
    SidebarButtonComponent,
    SidebarComponent,
    FormComponent,
    ButtonComponent,
    RedirectComponent,
    PaginationComponent,
    PageHeaderComponent,
    UserInfoComponent,
    LastAccessComponent,
    NotificationsComponent,
    ActionBoxComponent,
    RequestsContractsComponent,
    IconComponent,
    AccomodationsWidgetComponent,
    NoDataComponent,
    AccomodationCardComponent,
    TabsComponent,
    CapitalizePipe,
    MapComponent,
    StatusBadgeComponent,
    ModalComponent,
    UploadDocumentsComponent,
    LocationFacilitiesComponent,
    StepperComponent,
    InfoPageComponent,
    UserImageInfoComponent,
    ApplicationRecapComponent,
    LightblueBoxComponent,
    NotificationErrorTemplateComponent,
    CardComponent
  ],
  exports: [
    SidebarButtonComponent,
    SidebarComponent,
    FormComponent,
    ButtonComponent,
    PaginationComponent,
    PageHeaderComponent,
    UserInfoComponent,
    LastAccessComponent,
    NotificationsComponent,
    ActionBoxComponent,
    RequestsContractsComponent,
    IconComponent,
    AccomodationsWidgetComponent,
    AccomodationCardComponent,
    TabsComponent,
    CapitalizePipe,
    MapComponent,
    StatusBadgeComponent,
    ModalComponent,
    UploadDocumentsComponent,
    NoDataComponent,
    LocationFacilitiesComponent,
    StepperComponent,
    InfoPageComponent,
    UserImageInfoComponent,
    ApplicationRecapComponent,
    LightblueBoxComponent,
    NotificationErrorTemplateComponent,
    CardComponent
  ],
  imports: [
    CommonModule,
    NzButtonComponent,
    NzInputDirective,
    ReactiveFormsModule,
    NzFormLabelComponent,
    NzPaginationComponent,
    NzTabSetComponent,
    NzTabComponent,
    NzModalComponent,
    NzModalModule,
    NzDatePickerComponent,
    NzSelectComponent,
    NzOptionComponent,
    NzInputGroupComponent,
    AngularEditorModule,
    NzCheckboxComponent,
    NzIconModule,
    NzTooltipDirective
  ]
})
export class SharedModule { }
