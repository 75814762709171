<div class="badge">
  @switch (status){
    @case('assigned'){
      <div class="green dot"></div>
      <span class="green">
        @switch (type){
          @case('accomodation'){
            Assegnato
          }
          @case('application'){
            Assegnata
          }
          @default {
            Completata
          }
        }
      </span>
    }
    @case('approved'){
      <div class="yellow dot"></div>
      <span class="yellow">
        @switch (type){
          @case('accomodation'){
            Approvato
          }
          @case('application'){
            Approvata
          }
        }
      </span>
    }
    @case('on_hold'){
      <div class="blue dot"></div>
      <span class="yellow">
        @switch (type){
          @case('accomodation'){
            In attesa di approvazione
          }
          @case('application'){
            In attesa
          }
          @default {
            In attesa del contratto
          }
        }
      </span>
    }
    @case('rejected'){
      <div class="red dot"></div>
      <span class="yellow">
        @switch (type){
          @case('accomodation'){
            Rifiutato
          }
          @case('application'){
            Rifiutata
          }
        }
      </span>
    }
  }
</div>
