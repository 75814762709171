import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonConfig } from '../../../../core/interfaces/button-config';
import { ActionBoxData } from '../../../../core/interfaces/action-box-data';

@Component({
  selector: 'app-action-box',
  templateUrl: './action-box.component.html',
  styleUrl: './action-box.component.scss'
})
export class ActionBoxComponent implements OnChanges {
  @Input() style: any = '';
  @Input() data: ActionBoxData|null = null;

  button_config: ButtonConfig = {
    type: 'primary',
    label: this.data?.button_text ?? 'Invia',
    submit: false,
    shade: 'dark',
    callback: () => null
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.button_config.label = changes['data']?.currentValue.button_text ?? this.button_config.label;
    this.button_config.callback = changes['data']?.currentValue.button_action ?? null;
  }
}
