import { ButtonConfig } from '../../core/interfaces/button-config';
import { AccomodationInfoLabel } from '../../core/interfaces/accomodation-info-label';
import { SelectOption } from '../../core/interfaces/select-option';

/**
 * Imposta dinamicamente le callback di un bottone
 * @param button_config configurazione completa del bottone
 * @param callback funzioni da aggiungere alla callback di button_config
 */
export function getButtonDynamicConfig(button_config: ButtonConfig, callback: (...args: any[]) => void){
  return {
    ...button_config,
    callback: () => callback()
  };
}

/**
 * Gestisce lo scorrimento delle card
 * @param action previous/next
 * @param index indice corrente
 * @param cards_length totale delle cards
 */
export function changeCard(action: string, index: number, cards_length: number): number{
  //Decrementa l'indice e torna all'ultimo elemento se siamo al primo
  if (action === 'previous') {
    index = (index - 1 + cards_length) % cards_length;
  }
  //Incrementa l'indice e torna al primo elemento se siamo all'ultimo
  else if (action === 'next') {
    index = (index + 1) % cards_length;
  }

  return index;
}

/**
 * Opzioni generiche per la select
 */
export function getSelectOptions(): SelectOption[]{
  return [
    { label: 'Sì', value: 1 },
    { label: 'No', value: 0 }
  ]
}

/**
 * Trasforma il FormGroup in un FormData
 */
export function formToFormData(params: any): FormData{
  const formData = new FormData();

  for(let key in params){
    formData.append(key, params[key] ?? '');
  }

  return formData;
}

/**
 * Downloads the file with the given path and name
 * @param path
 * @param name
 */
export function downloadFile(path: string, name: string){
  fetch(path)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
    })
    .catch(
      error => console.error('Errore nel download del file:', error)
    );
}

/**
 * Maps the data for the accomodation additional details
 */
export function getAccomodationInfoDetails(): AccomodationInfoLabel[] {
  return [
    {
      key: 'typology',
      label: 'Tipologia dell\'immobile',
      type: 'checkbox-group',
      options: [
        { label: 'Appartamento', value: 'apartment' },
        { label: 'Stanza/Posto letto', value: 'room' }
      ]
    },
    {
      key: 'architectural_barriers',
      label: 'Presenza di eventuali barriere architettoniche',
      type: 'select',
      options: getSelectOptions()
    },
    {
      key: 'bathrooms_count',
      label: 'Numero bagni'
    },
    {
      key: 'bedrooms_count',
      label: 'Numero camere'
    },
    {
      key: 'beds_count',
      label: 'Numero posti letto'
    },
    {
      key: 'cadastral_category',
      label: 'Cat catastale'
    },
    {
      key: 'cadastral_parcel',
      label: 'Part'
    },
    {
      key: 'cadastral_sheet',
      label: 'Foglio'
    },
    {
      key: 'conservation_state',
      label: 'Stato di conservazione'
    },
    {
      key: 'construction_year',
      label: 'Anno di costruzione'
    },
    {
      key: 'furniture',
      label: 'Presenza di eventuale mobilio',
      type: 'select',
      options: getSelectOptions()
    },
    {
      key: 'garage',
      label: 'Presenza di garage',
      type: 'select',
      options: getSelectOptions()
    },
    {
      key: 'heating_system',
      label: 'Impianto di riscaldamento',
      type: 'select',
      options: getSelectOptions()
    },
    {
      key: 'lease_amount',
      label: 'Importo di locazione proposto',
      afterAddOn: '€'
    },
    {
      key: 'living_area',
      label: 'Superfici abitabili',
      afterAddOn: 'm²'
    },
    {
      key: 'parking_slots_count',
      label: 'Numero posti auto'
    },
    {
      key: 'subordinate',
      label: 'Sub'
    }
  ]
}

/**
 * Retrieves the selected option in the dataset
 * @param selectedOption selected value
 * @param options values dataset
 */
export function getSelectedOption(selectedOption: any, options: SelectOption[]): string{
  return options.find((option: SelectOption) => option.value === selectedOption)?.label!;
}
